import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { get, head, isEqual, sample, map } from '../../lib/nodash';
import { Box, ThemeContext } from 'grommet';
import loadable from '@loadable/component';

import { getClientForUrl, getKeyFromUrl } from '../../lib/imgix';
const DynamicImageContentModule = loadable(() =>
  import('./DynamicImageContentModule')
);
const ContentSlider = loadable(() => import('../ContentSlider'));
import isBlank from '../../lib/isBlank';

const DynamicMultiImageContentModule = ({ blok }) => {
  const {
    images,
    fit = 'cover',
    swiperParams = {},
    displayAs = 'slider',
    slidesPerView = 1,
    mobileSlidesPerView = 1,
    spaceBetween,
    navigation = false,
    navColor,
    loop = false,
    thumbnailPagination = false,
    autoHeight = false,
    sizes,
  } = blok;
  const theme = useContext(ThemeContext);
  const pixelsBetween = spaceBetween
    ? parseInt(theme.global.edgeSize[spaceBetween])
    : 0;
  const singleImage = isEqual('oneRandom', displayAs)
    ? sample(images)
    : head(images);

  const thumbnailImages = useMemo(() => {
    return thumbnailPagination
      ? map((x) => {
          const client = getClientForUrl(
            x.image?.filename || x.image?.originalSrc || x.originalSrc || x.src
          );
          const imgixSrc = client.buildURL(
            getKeyFromUrl(
              x.image?.filename ||
                x.image?.originalSrc ||
                x.originalSrc ||
                x.src
            ),
            {
              q: 75,
              w: 50,
              h: 50,
              fit: 'crop',
              crop: 'entropy',
              auto: ['compress', 'format'],
            }
          );
          return { image: { ...x, src: imgixSrc }, fit: fit, fill: true };
        }, images)
      : undefined;
  }, [images, thumbnailPagination]);

  const pagination = useMemo(() => {
    return thumbnailPagination
      ? {
          clickable: true,
          className: 'thumbs',
          renderBullet: (index, className) => {
            return thumbnailImages.length
              ? `<div
        class='${className} thumb'>
          <img alt='${thumbnailImages[index]?.image?.altText}' src='${thumbnailImages[index]?.image?.src}' />
        </div>`
              : '';
          },
        }
      : false;
  }, [thumbnailImages]);

  const params = {
    ...swiperParams,
    slidesPerView: parseInt(
      isBlank(mobileSlidesPerView) ? 1 : mobileSlidesPerView
    ),
    spaceBetween: pixelsBetween,
    navigation,
    loop,
    pagination,
    autoHeight,
    breakpoints: {
      [theme.global.breakpoints.small.value]: {
        slidesPerView: parseInt(slidesPerView),
        spaceBetween: pixelsBetween,
        centeredSlides: false,
      },
    },
  };

  return (
    <Box fill>
      {images.length === 1 ? (
        <DynamicImageContentModule blok={singleImage} fit={fit} />
      ) : (
        <>
          {isEqual('oneRandom', displayAs) ? (
            <DynamicImageContentModule blok={singleImage} fit={fit} />
          ) : (
            <ContentSlider
              params={params}
              navColor={navColor}
              swiperStyle={{ height: '100%', width: '100%' }}
            >
              {images.map((x) => {
                return (
                  <DynamicImageContentModule
                    key={
                      get('image.id', x) ||
                      get('image.originalSrc', x) ||
                      x.id ||
                      x._uid ||
                      x.uuid ||
                      x.slug
                    }
                    blok={x}
                    fit={fit}
                    inSwiper={true}
                    sizes={sizes}
                    {...x}
                  />
                );
              })}
            </ContentSlider>
          )}
        </>
      )}
    </Box>
  );
};

DynamicMultiImageContentModule.propTypes = {
  blok: PropTypes.shape({
    images: PropTypes.array,
    fit: PropTypes.string,
    imgStyle: PropTypes.object,
    swiperParams: PropTypes.object,
    displayAs: PropTypes.string,
    slidesPerView: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mobileSlidesPerView: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    spaceBetween: PropTypes.oneOf([
      'xsmall',
      'small',
      'medium',
      'large',
      'xlarge',
    ]),
    navigation: PropTypes.bool,
    loop: PropTypes.bool,
    autoHeight: PropTypes.bool,
    navColor: PropTypes.string,
    thumbnailPagination: PropTypes.bool,
    sizes: PropTypes.string,
  }),
};

export default DynamicMultiImageContentModule;
